import React from 'react';
import Chatbot from './Chatbot';
import './Chatbot.css';

const ChatbotWrapper = ({ thisCb, chatbotImg, customPrompt, token }) => {
  console.log('ChatbotWrapper props:', { thisCb, chatbotImg, customPrompt, token });

  return (
    <div>
      <Chatbot 
      	thisCb={thisCb} 
      	chatbotImg={chatbotImg} 
      	token={token}
      />
    </div>
  );
};

export default ChatbotWrapper;