import React, { useEffect, useRef, useState } from "react";
import withRouter from "components/Common/withRouter";
import { 
  Col, 
  Row, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { BASE_URL } from "utils/index";
import axiosInstance from 'utils/axiosInstance';

import { FaStar } from 'react-icons/fa';
import RatingComponent from './modals/RatingComponent';
import StarRating from './modals/StarRating'; // Import your StarRating component
 
 // WebSocket communication
import { io } from "socket.io-client";

// Import file icons and images
import placeholderImg from "assets/images/placeholders/placeholder_card.png";

import './Chatbot.css';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import ChatMessagesModalView from './ChatMessagesModalView';

/*---- Example Message ----*/
/*
const messages = [
  { id: 1, text: "Hello, how can I help you?", sender: "received", time: "18:45"  },
  { id: 2, text: "I have a question about my order.", sender: "sent", time: "18:45"  },
  { id: 3, text: "Sure, what is your order number?", sender: "received", time: "18:46"  },
  { id: 4, text: "It's #1234.", sender: "sent", time: "18:46"  }
];
*/

// Function to parse text and convert it to JSX with bold formatting
const parseTextToJSX = (text) => {
  // Use regex to remove 'undefined' from the end of the string if it exists
  const cleanedText = text.replace(/undefined$/, '');

  // Replace LaTeX-style \text{} with the actual content
  let processedText = cleanedText.replace(/\\text\{([^}]+)\}/g, '$1');

  // Replace LaTeX-style \[ ... \] (math expressions) with the content in parentheses
  processedText = processedText.replace(/\\\[(.*?)\\\]/gs, '($1)');

  // Replace \times with the multiplication symbol ×
  processedText = processedText.replace(/\\times/g, '×');

  // Replace \% with the percentage symbol %
  processedText = processedText.replace(/\\%/g, '%');

  // Define the URL regex pattern (http or https)
  const urlPattern = /https?:\/\/[^\s)）\]，。]+/g;

  // Split the text into parts by bold text and URLs
  const parts = processedText.split(/(\*\*[^*]+\*\*|https?:\/\/[^\s)）\]，。]+)/);

  return parts.map((part, index) => {
    // Handle bold text
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    // Handle URLs
    if (urlPattern.test(part)) {
      return (
        <a href={part} key={index} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    // For regular text (including transformed math expressions)
    return <span key={index}>{part}</span>;
  });
};

const Chatbot = ({ 
  thisAgent, 
  agentImg, 
  token, 

  headerColor, 
  headerHeight,
  headerTextColor, 
  headerFontSize,
  headerBorderRadius, 

  conversationBoxHeight,
  conversationBoxFontSize,
  conversationBoxTimeFontSize,
  conversationBoxTimeTextColor,
  conversationBoxBackgroundColor,

  userMessageBackgroundColor,
  AIMessageBackgroundColor,
  userMessageTextColor,
  AIMessageTextColor,

  inputFontSize,
  inputTextColor,
  inputMarginColor,
  inputBackgroundColor,
  sendIconSize,
  sendIconColor,
}) => {


  /*---- Props and States ----*/

  // Store the token in a global variable 
  // When chatbot used in client sites
  if(token){
    window.chatbotAuthToken = token;
  // When chatbot used in own platform
  } else {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const authToken = authUser?.token;
    window.chatbotAuthToken = authToken;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const [isRealTime, setIsRealTime] = useState(false); // Track if real-time customer service is active
  const [socket, setSocket] = useState(null); // Store WebSocket connection

  const [message, setMessage] = useState(''); // message within dialog input
  const [messages, setMessages] = useState([]); // the whole set of dialog

  const messagesEndRef = useRef(null); // For conversation scroll to bottom
  const setMessagesEndRef = (node) => {
    messagesEndRef.current = node;
  }
  const inputTextareaRef = useRef(null); // For textarea height adjustment
  const setInputTextareaRef = (node) => {
    //console.log('setting inputtextarearef', node);
    inputTextareaRef.current = node;
    adjustTextareaHeight();
  };

  const [isModalView, setIsModalView] = useState(false);
  const modalMessagesEndRef = useRef(null); // For conversation scroll to bottom
  const modalInputTextareaRef = useRef(null); // For textarea height adjustment

  let jsonPart='', textPart='';

  /*---- Props and States Tail ----*/



  /*---- For Agent Functionalities ----*/

  const [currentTopic, setCurrentTopic] = useState('');
  const [currentTopicDetail, setCurrentTopicDetail] = useState('');

  const decideTopic = async () => {
    const messageToDecide = [...messages.map(m=>m.text), message];
    //console.log("Deciding current topic...", messageToDecide);
    try{

      const response = await axiosInstance.post(`/knovia/agent-topicdecide/${thisAgent._id}`,
        messageToDecide,
        {  headers: { 'Content-Type': 'application/json' } }
      );
      const { topic } = response.data;
      const cleanedJsonString = topic.replace(/```json|```/g, '').trim();
      const parsedTopicData = JSON.parse(cleanedJsonString);

      setCurrentTopic(parsedTopicData["主題"]);
      setCurrentTopicDetail(parsedTopicData["主題細項"]);
    } catch (e) {
      console.log(e);
    }
  }

  /*---- For Agent Functionalities Tail ----*/





  /*---- Text Window Related Settings ----*/

  // Use useEffect to scroll to the bottom every time messages change
  // Use useEffect to scroll to the bottom every time messages change
  useEffect(() => {
    //console.log('scrolling to the newest message', messagesEndRef.current);
    
    if (messagesEndRef.current) { 
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
    /*
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    */

    /*
    if (modalMessagesEndRef.current) { 
      modalMessagesEndRef.current.scrollTop = modalMessagesEndRef.current.scrollHeight;
    }
    */
  }, [messages]); // Runs when messages change

  useEffect(() => {
    const setVh = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
    window.addEventListener('resize', setVh);
    setVh(); // Set the initial value
    
    return () => window.removeEventListener('resize', setVh);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    //console.log('[adjustTextareaHeight] inputTextareaRef.current:', inputTextareaRef.current);
    if (inputTextareaRef.current) {
      inputTextareaRef.current.style.height = "auto"; 
      if(inputTextareaRef.current.scrollHeight>36){
        inputTextareaRef.current.style.height = `${inputTextareaRef.current.scrollHeight}px`;
      } else {
        inputTextareaRef.current.style.height = `${Math.max(40, inputFontSize)}px`;
      }
    }
  };

  // Change in textarea input
  const handleChange = (e) => {
    setMessage(e.target.value);
    adjustTextareaHeight();
  };

  const wordLimit = 100;
  const [customerComment, setCustomerComment] = useState('');
  const clientCommentRef = useRef(null);
  const handleCustomerCommentChange = (e)=>{
    if(e.target.value.length <= wordLimit){
      setCustomerComment(prevComment => e.target.value);
    }
  }

  useEffect(()=>{
    const adjustClientCommentHeight = () => {
      if (clientCommentRef.current) {
        clientCommentRef.current.style.height = "auto"; 
        if(clientCommentRef.current.scrollHeight>100){
          clientCommentRef.current.style.height = `${clientCommentRef.current.scrollHeight}px`;
        } else {
          clientCommentRef.current.style.height = `${Math.max(100, inputFontSize)}px`;
        }
      }
    };
    adjustClientCommentHeight();
  },[customerComment])

  useEffect(()=>{
    //console.log('HERE3', isModalView);
    //console.log('HERE4', inputTextareaRef.current);
    adjustTextareaHeight();
  },[isModalView, inputTextareaRef.current])


  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Keep small chat window closed and open large window
  useEffect(()=>{
    if(isOpen && isSmallScreen){
      setIsOpen(false);
      setIsModalView(true);
    }
  },[isOpen, isSmallScreen])


  const toggleChatWindow = () => { setIsOpen(!isOpen) };
  const closeChatWindow = () => { 
    if(conversationId && !selectedRating){
      setIsRatingModalOpen(true);
    }
    setIsOpen(false);
  };

  /*---- Text Window Related Settings ----*/


  const callService = async ()=>{

    if(!conversationId){
      return;
    }

    try {
      const response = await axiosInstance.post('/knovia/agent/call-service', { 
        conversationId: conversationId 
      });
      //console.log('response:', response);
    } catch (error) {
      console.error('Error requesting real customer service:', error);
    }

    if (!isRealTime) {
      // If real-time service is not active, switch to WebSocket
      const newSocket = io(BASE_URL); // Adjust to your backend WebSocket URL
      
      // Join a room using the conversationId as roomId
      newSocket.emit('joinRoom', { username: "User", room: conversationId });

      // Listen for incoming messages in the room
      newSocket.on("chatMessage", (msg) => {
        // Receive message from WebSocket and update UI
        //console.log('[Chatbot.js] received msg:', msg);
        //console.log('[Chatbot.js] received Message:', msg.message);
        if(msg.sender!=='User'){
          setMessages((prevMessages) => [...prevMessages, 
            {
              conversationId: msg.conversationId,
              sender: "received",
              text: msg.message,
              time: new Date(msg.time).toLocaleTimeString(),
            }
          ]);
        }
      });

      // Set the WebSocket and update the UI
      setSocket(newSocket);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "received", text: "[已為您轉接真人客服...請稍待片刻]" },
      ]);
    } else {
      // If real-time service is active, disconnect WebSocket and switch back to AI
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "received", text: "[已為您轉接至AI客服...]" },
      ]);
    }
    setIsRealTime(!isRealTime); // Toggle real-time service state
  };


  /*---- Conversation with Backend ----*/
  useEffect(()=>{ 
    //console.log('Now messages:', messages) 
  },[messages])

  const sendMessage = async () => {
    if (!message.trim()) return;

    //await decideTopic();

    // Reset the input field after sending the message
    setMessage('');
    if (inputTextareaRef && inputTextareaRef.current && inputTextareaRef.current.style) {
      if(isModalView){
        // Input in large window
        inputTextareaRef.current.style.height = `${Math.max(18, inputFontSize*1.66)}px`;
      } else {
        // Input in small window
        inputTextareaRef.current.style.height = '40px';
      }
    }

    if (message.trim()) {

      /*---- Optimistic Update of user message ----*/

      const tempMessageId = Date.now(); // Temporary ID for optimistic UI update
      const tempBotMessageId = 'tempBot';
      let conversation, processedMessages, botImageMessages;

      let parsedData = {};
      let jsonPart, textPart;
      let optimisticUserMessage={}, optimisticBotMessage={};

      if (isRealTime) {
        optimisticUserMessage = {
          id: tempMessageId,
          text: message,
          sender: 'sent',
          time: new Date().toLocaleTimeString(),
        };
        // Add the optimistic user message
        setMessages(prevMessages => [...prevMessages, optimisticUserMessage]);
      } else {
        optimisticUserMessage = {
          id: tempMessageId,
          text: message,
          sender: 'sent',
          time: new Date().toLocaleTimeString(),
        };

        optimisticBotMessage = {
          id: tempBotMessageId,
          text: message,
          sender: 'loading',
          time: new Date().toLocaleTimeString(),
        };

        // Add the optimistic user message and bot response
        setMessages(prevMessages => [...prevMessages, optimisticUserMessage, optimisticBotMessage]);
      }
      /*---- Optimistic Update of user message ----*/



      /*---- Post and Receive streaming Response ----*/

      if (isRealTime) {
        // If WebSocket is connected, send message through WebSocket
        socket.emit('chatMessage', {
          sender: 'User',
          message,
          room: conversationId,
          time: new Date(),
        });
      } else {
        const data = { 
          sender: 'User',
          message, 
          agentId: thisAgent._id,
          conversationId,
        };

        try {

          const response = await axiosInstance.post(`/knovia/agent/conversations`, data,
            {
              headers: { 'Content-Type': 'application/json' },
              onDownloadProgress: (evt) => {

                // Check if response status is 200/201 before processing response data
                if (evt.event.target.status === 200 || evt.event.target.status === 201) {

                  const currentResponse = evt.event.target.response.replace('undefined', '');


                  // Bot message from backend completed
                  let splitIndex = currentResponse.indexOf('\n\n\n\n');
                  
                  if (splitIndex !== -1) {
                    textPart = currentResponse.substring(0, splitIndex);
                    jsonPart = currentResponse.substring(splitIndex + 4);

                    //console.log('Text Part:', textPart);
                    //console.log('Json Part:', jsonPart);

                    // Get JSON Part
                    try {
                      if (jsonPart.trim()) {
                        const parsedData = JSON.parse(jsonPart);

                        // Assuming parsedData contains the necessary information
                        ({ conversation, processedMessages, botImageMessages } = parsedData);

                        // Check and update conversation ID if needed
                        if (!conversationId && conversation._id) { 
                          setConversationId(conversation._id) 
                        }

                        if(processedMessages){
                          // Update the messages in the UI
                          setMessages(prevMessages => {

                            // Filter out the loading and optimistic messages
                            let updatedMessages = prevMessages.filter(message => message.id !== tempMessageId && message.id !== tempBotMessageId);

                            // Add the user message confirmed by the server
                            if (processedMessages.userMessage) {
                              updatedMessages.push({
                                ...optimisticUserMessage,
                                id: processedMessages.userMessage._id, // Update ID with the one from server
                              });
                            }

                            // Add the new bot message
                            if (processedMessages.botMessage) {
                              updatedMessages.push({
                                id: processedMessages.botMessage._id,
                                text: textPart,
                                sender: 'received',
                                time: new Date().toLocaleTimeString(), // Adjust the time as per your need
                              });
                            }

                            // Add the bot image messages
                            if (botImageMessages && botImageMessages.length > 0) {
                              
                              botImageMessages.forEach((botImageMessage, index) => {
                                const base64Image = botImageMessage.image.data;
                                //console.log('base64Image:', base64Image.slice(0,100), '...');
                                const imageUrl = `data:${botImageMessage.image.contentType};base64,${base64Image}`;

                                updatedMessages.push({
                                  id: `${processedMessages.botMessage._id}-image-${index}`,
                                  text: botImageMessage.text,
                                  imageUrl: imageUrl,
                                  sender: 'received',
                                  time: new Date().toLocaleTimeString(),
                                });
                              });
                            }

                            return updatedMessages;
                          });
                        }

                      }
                      
                    } catch (e) {
                      console.log('error', e);
                    }

                  // Bot message from backend not completed yet
                  } else {
                    // Update the messages in the UI
                    setMessages(prevMessages => {
                      // Filter out the previous incomlete bot message
                      let updatedMessages = prevMessages.filter(message => message.id !== tempBotMessageId);
                      let newStreamMessage = {};

                      // Add the new bot message
                      if (currentResponse) {
                        newStreamMessage = {
                          id: tempBotMessageId,
                          text: currentResponse,
                          sender: 'received',
                          time: new Date().toLocaleTimeString(), // Adjust the time as per your need
                        }
                      }

                      return [...updatedMessages, newStreamMessage];
                    });
                  }
                }
              }
            }
          );

          // Check response status before updating final messages
          if (response.status === 200 || response.status === 201) {
            console.log('Stream completed successfully');
          } else {
            console.error('Non-200/201 response received:', response.status);
          }

        } catch (error) {
          console.error('Error fetching stream:', error);
        }

      }

      /*---- Update User Message Tail ----*/

    }

  };
  /*---- Conversation with Backend ----*/


  /*---- Get Agent Data for client website widget ----*/
  const [thisServiceSchedule, setThisServiceSchedule] = useState(null);
  const getServiceSchedule = async () => {
    console.log("Getting service schedule...");

    try{
      const response = await axiosInstance.get(`/knovia/agent/service-schedule/${thisAgent._id}`);
      setThisServiceSchedule(response.data.customerServiceSchedule);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if(!thisServiceSchedule && thisAgent && thisAgent._id){
      getServiceSchedule();
    }
  }, [thisAgent]);


  /*---- Modal viewing the images ----*/

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTitle, setSelectedImageTitle] = useState('');

  const toggleModal = () => setModalOpen(!modalOpen);

  const onImageClick = (imageData, imageTitle='瀏覽圖片') => {
    setSelectedImageTitle(imageTitle);
    setSelectedImage(imageData);
    setModalOpen(true);
  };

  /*---- Modal viewing the images Tail ----*/


  // Function to check if the current time falls within the customer service schedule
  const isWithinCustomerServiceHours = (customerServiceSchedule = thisServiceSchedule) => {
    if (customerServiceSchedule) {
      const currentDate = new Date();
      const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
      const currentTime = currentDate.toTimeString().slice(0, 5); // Get current time in 'HH:MM' format

      const scheduleForToday = customerServiceSchedule[currentDay];
      if (!scheduleForToday || !scheduleForToday.start || !scheduleForToday.end) {
        // No schedule for today or invalid times
        return false;
      }

      const { start, end } = scheduleForToday;
      
      // Check if the schedule wraps around midnight
      const wrapsAroundMidnight = start > end;

      if (wrapsAroundMidnight) {
        // If the current time is after the start time OR before the end time
        console.log('HERE2', currentTime, start, end);
        console.log('currentTime >= start:', currentTime >= start)
        console.log('currentTime <= end:', currentTime <= end);
        return currentTime >= start || currentTime <= end;
      } else {
        // If the schedule is within the same day
        return currentTime >= start && currentTime <= end;
      }
    }
    return true; // Default to true if no schedule is provided
  };


  /*---- Main ChatBot Component ----*/



  /*---- User Rating of the Conversation ----*/

  const [selectedRating, setSelectedRating] = useState(null);
  const [temporateRating, setTemporateRating] = useState(null);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const toggleRatingModal = ()=>{ setIsRatingModalOpen(!isRatingModalOpen); }

  const handleRatingSelected = (rating) => { setTemporateRating(rating); };
  const handleResetRating = () => { setTemporateRating(0); };

  const handleConfirmRating = async () => {
    if(temporateRating>=0 && temporateRating<=5){
      setSelectedRating(temporateRating);
      //console.log(`Confirm selecting rating: ${temporateRating} stars`);

      if(conversationId){
        // Send the updated rating to the backend
        try {
          await axiosInstance.put(`/knovia/conversation-rating/user/${conversationId}/`, { 
            userRating: temporateRating,
            customerComment: customerComment
          });
          //console.log("User Rating of the conversation updated successfully");
        } catch (error) {
          console.error("Error updating user conversation rating", error);
        }
      }

    }
  };
  const resetSelectedRating = () => {
    setSelectedRating(null);
    setTemporateRating(null);
  }

  /*---- User Rating of the Conversation ----*/



  return (
    <React.Fragment>
      <div className="chatbot-container">


        {/* Small Chatbot Window */}
        <div className={`chat-window 
          ${(isOpen&&!isModalView&&!isSmallScreen) ? 'open' : ''}`
          } 
          style={{ 
            right: '15vw', 
            zIndex: '-1',
            borderTopLeftRadius: `${headerBorderRadius}px`,
            borderTopRightRadius: `${headerBorderRadius}px`,
          }}
        >

          {/* Chat Window Header Bar */}
          <div 
            className="chat-header" 
            style={{ 
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'center',
              height: `${headerHeight}px`,
              backgroundColor: headerColor,
              borderTopLeftRadius: `${headerBorderRadius}px`,
              borderTopRightRadius: `${headerBorderRadius}px`,
            }}
          >
            <Row style={{ width:'100%', marginLeft:'0px' }}>
              <Col xs={9} 
                style={{ 
                  display: 'flex',
                  alignContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <p 
                  className="chat-header-text"
                  style={{
                    color: headerTextColor,
                    fontSize: `${headerFontSize}px`,
                    fontWeight: '500'
                  }}
                >
                  {`${thisAgent?.agentName}(測試中)` || ''}
                </p>
              </Col>
              <Col xs={3}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
              >
                <button className="enlarge-chat" onClick={() => setIsModalView(true)} style={{ display: isModalView ? 'none' : 'block' }}>⤡</button>
                <button className="close-chat" onClick={closeChatWindow}>&times;</button>
              </Col>
            </Row>
          </div>

          {/*
          <div 
            style={{ minHeight: '10vh', marginBottom: '-10vh', background: '#fffdf7',
              zIndex: '0', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0vh 3vw'
            }}
          >
            <p className="mb-0">主題：{currentTopic}</p>
            <p className="mb-0">主題細項：{currentTopicDetail}</p>
          </div>
          */}


          {/* Use the ChatMessages component */}
          <ChatMessages 
            thisAgent={thisAgent}
            messages={messages} 
            messagesEndRef={messagesEndRef} 
            setMessagesEndRef={setMessagesEndRef}
            parseTextToJSX={parseTextToJSX}
            onImageClick={onImageClick}

            conversationBoxHeight={conversationBoxHeight}
            conversationBoxFontSize={conversationBoxFontSize}
            conversationBoxTimeFontSize={conversationBoxTimeFontSize}
            conversationBoxTimeTextColor={conversationBoxTimeTextColor}
            conversationBoxBackgroundColor={conversationBoxBackgroundColor}

            userMessageBackgroundColor={userMessageBackgroundColor}
            AIMessageBackgroundColor={AIMessageBackgroundColor}
            userMessageTextColor={userMessageTextColor}
            AIMessageTextColor={AIMessageTextColor}
          />

          <RatingComponent
            conversationId={conversationId}
            setIsRatingModalOpen={setIsRatingModalOpen}
            selectedRating={selectedRating}

            conversationBoxFontSize={conversationBoxFontSize}
            conversationBoxBackgroundColor={conversationBoxBackgroundColor}
          />

          {/* Modal for selecting the rating */}
          <Modal isOpen={isRatingModalOpen} toggle={toggleRatingModal}>
            <ModalHeader 
              toggle={toggleRatingModal}
              style={{ padding: '1.5rem 3rem' }}
            >
              <p style={{ margin:'0', fontSize: `${headerFontSize}px` }}>為此對話評分</p>
            </ModalHeader>
            <ModalBody
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0',
              }}
            >
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0px 2rem',
                }}
              >
                <Col xs={12} sm={9}
                  style={{
                    //width: '80%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <StarRating
                    selectedRating={temporateRating}
                    onRatingSelected={handleRatingSelected} // Update the rating
                  />
                </Col>
                <Col xs={12} sm={3}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '5px'
                  }}
                >
                  {/* Add the reset button beside the stars */}
                  <Button 
                    color="outline-secondary"
                    onClick={handleResetRating} 
                    style={{ cursor: 'pointer', fontSize: '12px', maxWidth: '100%' }}
                  >
                    重置
                  </Button>
                </Col>
              </Row>

              <div style={{ width: '80%' }}>

                 <textarea
                  className="customer-comment"
                  rows="1"
                  placeholder="[您的評論(選填)]" 
                  value={customerComment}
                  onChange={handleCustomerCommentChange}
                  ref={clientCommentRef}
                  //onKeyPress={handleKeyPress}
                  style={{ 
                    resize: 'none',
                    outline: '0px',
                    height: '100px',
                    border: '1.5px solid #acbcbc',
                    borderRadius: '10px',
                    fontSize: '20px',
                    marginTop: '30px',
                    color: 'rgb(139 139 139)',
                    backgroundColor: 'rgb(255, 255, 255)',
                    width: '100%'
                  }}
                />
                <div>還可輸入 {wordLimit - customerComment.length} 個字</div>

              </div>

            </ModalBody>
            <ModalFooter
              style={{
                padding: '1rem 1.5rem',
                gap: '0.7rem'
              }}
            >
              <Button color="primary" 
                onClick={()=>{
                  toggleRatingModal();
                  handleConfirmRating();
                }}
              >完成</Button>
              <Button color="secondary" onClick={toggleRatingModal}>取消</Button>
            </ModalFooter>
          </Modal>



          <ChatInput 
            thisAgent={thisAgent}
            inputTextareaRef={inputTextareaRef}
            setInputTextareaRef={setInputTextareaRef}
            callService={callService}
            conversationId={conversationId}
            isRealTime={isRealTime}
            isWithinCustomerServiceHours={isWithinCustomerServiceHours}
            isModalView={isModalView}

            message={message}
            handleChange={handleChange}
            handleKeyPress={handleKeyPress}
            sendMessage={sendMessage}

            sendIconColor={sendIconColor}
            sendIconSize={sendIconSize}
            inputFontSize={inputFontSize}
            inputTextColor={inputTextColor}
            inputMarginColor={inputMarginColor}
            inputBackgroundColor={inputBackgroundColor}
          />

        </div>

      </div>


      {/* Chatbot Icon */}
      <button className="chatbot-button" onClick={toggleChatWindow} style={{ right: '1vw', zIndex: '1' }}>
        <img 
          className="chatbot-img" 
          style={{ 
            width: '8rem',
            height: '8rem',
            borderRadius: '30%',
            objectFit: 'cover',
            //maxWidth: '10vw'
          }} 
          src={ agentImg || placeholderImg } 
          alt="Chat"
        />
      </button>

      <ChatMessagesModalView 
        thisAgent={thisAgent}
        isModalView={isModalView}
        setIsModalView={setIsModalView}
        isWithinCustomerServiceHours={isWithinCustomerServiceHours}
        callService={callService}
        isRealTime={isRealTime}

        conversationId={conversationId}
        setIsRatingModalOpen={setIsRatingModalOpen}
        selectedRating={selectedRating}

        messages={messages}
        messagesEndRef={messagesEndRef}
        setMessagesEndRef={setMessagesEndRef}
        inputTextareaRef={inputTextareaRef}
        setInputTextareaRef={setInputTextareaRef}

        message={message}
        handleChange={handleChange}
        handleKeyPress={handleKeyPress}
        sendMessage={sendMessage}

        parseTextToJSX={parseTextToJSX} 
        onImageClick={onImageClick} 

        headerColor={headerColor}
        headerHeight={headerHeight}
        headerTextColor={headerTextColor}
        headerFontSize={headerFontSize}
        headerBorderRadius={headerBorderRadius}

        conversationBoxHeight={conversationBoxHeight}
        conversationBoxFontSize={conversationBoxFontSize}
        conversationBoxTimeFontSize={conversationBoxTimeFontSize}
        conversationBoxTimeTextColor={conversationBoxTimeTextColor}
        conversationBoxBackgroundColor={conversationBoxBackgroundColor}

        userMessageBackgroundColor={userMessageBackgroundColor}
        AIMessageBackgroundColor={AIMessageBackgroundColor}
        userMessageTextColor={userMessageTextColor}
        AIMessageTextColor={AIMessageTextColor}

        inputFontSize={inputFontSize}
        inputTextColor={inputTextColor}
        inputMarginColor={inputMarginColor}
        inputBackgroundColor={inputBackgroundColor}
        sendIconSize={sendIconSize}
        sendIconColor={sendIconColor}
      />


      {/* Modal Viewing Bot Images */}
      <Modal isOpen={modalOpen} toggle={toggleModal} style={{ display: 'flex', width: 'fit-content', maxWidth: 'fit-content' }}>
        <ModalHeader toggle={toggleModal}>{selectedImageTitle || '瀏覽圖片'}</ModalHeader>
        <ModalBody style={{ width: 'fit-content', height: 'auto', maxWidth:'75vw' }}>
          {selectedImage && (
            <img src={selectedImage} alt="Selected" style={{ maxWidth: '70vw', maxHeight: '70vh' }} />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>關閉</Button>
        </ModalFooter>
      </Modal>


    </React.Fragment>
  );
};

export default withRouter(Chatbot);






