import React, { useState, useEffect } from 'react';
import Chatbot from './Chatbot';
import './Chatbot.css';

import axiosInstance from 'utils/axiosInstance';

const AgentWrapper = ({ thisAgent, agentImg, customPrompt, token }) => {
  //console.log('AgentWrapper props:', { thisAgent, agentImg, customPrompt, token });

  const [headerColor, setHeaderColor] = useState('#00AABB');
  const [headerHeight, setHeaderHeight] = useState('70');
  const [headerTextColor, setHeaderTextColor] = useState('#FFFFFF');
  const [headerFontSize, setHeaderFontSize] = useState('18');
  const [headerBorderRadius, setHeaderBorderRadius] = useState('10');

  const [conversationBoxHeight, setConversationBoxHeight] = useState('350');
  const [conversationBoxFontSize, setConversationBoxFontSize] = useState('12');
  const [conversationBoxTimeFontSize, setConversationBoxTimeFontSize] = useState('10');
  const [conversationBoxTimeTextColor, setConversationBoxTimeTextColor] = useState('#6A6A6A');
  const [conversationBoxBackgroundColor, setConversationBoxBackgroundColor] = useState('#EEEEEE');

  const [userMessageBackgroundColor, setUserMessageBackgroundColor] = useState('#E6F9FA');
  const [AIMessageBackgroundColor, setAIMessageBackgroundColor] = useState('#FFFFFF');
  const [userMessageTextColor, setUserMessageTextColor] = useState('#000000');
  const [AIMessageTextColor, setAIMessageTextColor] = useState('#000000');

  const [inputFontSize, setInputFontSize] = useState('16');
  const [inputTextColor, setInputTextColor] = useState('#000000');
  const [inputMarginColor, setInputMarginColor] = useState('#FFFFFF');
  const [inputBackgroundColor, setInputBackgroundColor] = useState('#FFFFFF');
  const [sendIconSize, setSendIconSize] = useState('16');
  const [sendIconColor, setSendIconColor] = useState('#00AABB');

  useEffect(() => {
    const fetchAppearanceSettings = async () => {
      try {
        const response = await axiosInstance.get(`/knovia/agent/${thisAgent?._id}/chatbot/appearance`);

        // Filter out undefined values
        const data = Object.fromEntries(
          Object.entries(response.data).filter(([_, value]) => value !== undefined)
        );

        console.log('Appearance data:', data);

        setHeaderColor(data.headerColor);
        setHeaderHeight(data.headerHeight);
        setHeaderTextColor(data.headerTextColor);
        setHeaderFontSize(data.headerFontSize);
        setHeaderBorderRadius(data.headerBorderRadius);

        setConversationBoxHeight(data.conversationBoxHeight);
        setConversationBoxFontSize(data.conversationBoxFontSize);
        setConversationBoxTimeFontSize(data.conversationBoxTimeFontSize);
        setConversationBoxTimeTextColor(data.conversationBoxTimeTextColor);
        setConversationBoxBackgroundColor(data.conversationBoxBackgroundColor);

        setUserMessageBackgroundColor(data.userMessageBackgroundColor);
        setAIMessageBackgroundColor(data.AIMessageBackgroundColor);
        setUserMessageTextColor(data.userMessageTextColor);
        setAIMessageTextColor(data.AIMessageTextColor);

        setInputFontSize(data.inputFontSize);
        setInputTextColor(data.inputTextColor);
        setInputMarginColor(data.inputMarginColor);
        setInputBackgroundColor(data.inputBackgroundColor);
        setSendIconSize(data.sendIconSize);
        setSendIconColor(data.sendIconColor);

      } catch (error) {
        console.error('Error fetching appearance settings:', error);
      }
    };
    fetchAppearanceSettings();
  }, [thisAgent]);

  return (
    <div>
      <Chatbot 
      	thisAgent={thisAgent} 
      	agentImg={agentImg} 
      	token={token}

        headerColor={headerColor}
        headerHeight={headerHeight}
        headerTextColor={headerTextColor}
        headerFontSize={headerFontSize}
        headerBorderRadius={headerBorderRadius}

        conversationBoxHeight={conversationBoxHeight}
        conversationBoxFontSize={conversationBoxFontSize}
        conversationBoxTimeFontSize={conversationBoxTimeFontSize}
        conversationBoxTimeTextColor={conversationBoxTimeTextColor}
        conversationBoxBackgroundColor={conversationBoxBackgroundColor}

        userMessageBackgroundColor={userMessageBackgroundColor}
        AIMessageBackgroundColor={AIMessageBackgroundColor}
        userMessageTextColor={userMessageTextColor}
        AIMessageTextColor={AIMessageTextColor}

        inputFontSize={inputFontSize}
        inputTextColor={inputTextColor}
        inputMarginColor={inputMarginColor}
        inputBackgroundColor={inputBackgroundColor}
        sendIconSize={sendIconSize}
        sendIconColor={sendIconColor}
      />
    </div>
  );
};

export default AgentWrapper;