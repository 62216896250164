import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';

const StarRating = ({ selectedRating, onRatingSelected }) => {
  const [hover, setHover] = useState(null);

  // Determine the color based on the rating value
  const getStarColor = (ratingValue) => {
    if (ratingValue <= 2) {
      return "#ff5722"; // Red for 1-2 stars
    } else if (ratingValue === 3) {
      return "#ff9800"; // Orange for 3 stars
    } else {
      return "#ffeb3b"; // Yellow for 4-5 stars
    }
  };

  const handleClick = (ratingValue) => {
    onRatingSelected(ratingValue); // Pass the selected rating to the parent
  };

  // Use either the hovered or selected rating to determine the color for all stars
  const currentRating = hover || selectedRating;

  return (
    <div>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={selectedRating || undefined}
              onClick={() => handleClick(ratingValue)}
              style={{ display: 'none' }} // Hide the radio button
            />
            <FaStar
              className="star"
              color={ratingValue <= currentRating ? getStarColor(currentRating) : "#e4e5e9"}
              size={40}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;


