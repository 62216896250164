import React, { useRef } from 'react';
import placeholder from "assets/images/placeholders/placeholder_card.png"
// Custom Axios
import axiosInstance from './axiosInstance';

function deepEqual(a, b) {
  if (typeof a !== 'object' || typeof b !== 'object') { return a === b; }
  const keysA = Object.keys(a), keysB = Object.keys(b);
  if (keysA.length !== keysB.length) { return false; }
  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEqual(a[key], b[key])) { return false; }
  }
  return true;
}

export const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (!deepEqual(value, ref.current)) { ref.current = value; }
  return ref.current;
}

export const isValidImage = (file) => {
  // Define your size limit, for example 5MB
  const sizeLimit = 5 * 1024 * 1024; // 5MB in bytes
  // List of allowed file types
  const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
  // Check file type
  if (!allowedFileTypes.includes(file.type)) {
    //alert('Only JPG, PNG, and GIF files are allowed.');
    return false;
  }
  // Check file size
  if (file.size > sizeLimit) {
    alert(`File is too large. Maximum size is ${sizeLimit / 1024 / 1024}MB.`);
    return false;
  }
  // Additional checks can be added here (like image dimensions)
  return true; // If all checks pass, return true
}

// Function to log FormData entries
function logFormData(formData) {
  console.log('Logging FormData entries:');
  for (let pair of formData.entries()) {
    console.log(`${pair[0]}: ${pair[1] instanceof File ? pair[1].name : pair[1]}`);
  }
}

// Uploading the selected file to the server
export const uploadImg = async (newfilename, file) =>{

  // Allowed image formats
  const allowedFormats = ['jpg', 'jpeg', 'png', 'svg'];
  // Extract file extension and convert it to lowercase
  const fileExtension = file.name.split('.').pop().toLowerCase();

  // Check if the file's format is allowed
  if (!allowedFormats.includes(fileExtension)) {
    console.error(`File format not allowed: .${fileExtension}`);
    return { newImageUrl: '', uploadStatus: `File format .${fileExtension} not allowed` };
  }

  // Proceed with the upload if the format is allowed
  const formData = new FormData();
  formData.append('file', file, newfilename);
  // Debugging logs
  console.log('file:', file);
  console.log('newfilename:', newfilename);
  logFormData(formData);

  console.log('[imageUtils uploadImg()] Uploading Image to Server...', newfilename);
  
  if(formData){
    try {
      const response = await axiosInstance.post(`/knovia/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        const newImageUrl = await fetchImage(newfilename);
        return {newImageUrl, uploadStatus: 'File uploaded successfully!'}
      } else {
        return {newImageUrl:'', uploadStatus: 'Failed to upload file.'}
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      return {newImageUrl:'', uploadStatus: 'Error occurred during upload.'};
    }
  }

}


/*---- Utility functions for IndexedDB ----*/

const dbRequest = indexedDB.open("imageCacheDB", 1);

dbRequest.onupgradeneeded = function(event) {
  let db = event.target.result;
  db.createObjectStore("images", { keyPath: "filename" });
};

const writeToIDB = (filename, data) => {
  return new Promise((resolve, reject) => {
    const dbOpenRequest = indexedDB.open("imageCacheDB", 2); // Ensure this matches the version of your DB

    dbOpenRequest.onupgradeneeded = function(event) {
      let db = event.target.result;

      // Create object store if it doesn't exist
      if (!db.objectStoreNames.contains("images")) {
        db.createObjectStore("images", { keyPath: "filename" });
      }
    };

    dbOpenRequest.onsuccess = function(event) {
      let db = event.target.result;
      let transaction = db.transaction("images", "readwrite");
      let store = transaction.objectStore("images");

      let request = store.put({ filename: filename, data: data });
      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    };

    dbOpenRequest.onerror = function(event) {
      console.error("IndexedDB could not be opened:", event.target.errorCode);
      reject("IndexedDB could not be opened: " + event.target.errorCode);
    };
  });
};


const readFromIDB = (filename) => {
  return new Promise((resolve, reject) => {
    if (!filename) {
      reject('Invalid filename provided:', filename);
      return;
    }

    // Increment the version number to force schema update
    const dbOpenRequest = indexedDB.open("imageCacheDB", 2);

    dbOpenRequest.onupgradeneeded = function(event) {
      let db = event.target.result;
      console.log('Upgrading database...');

      // Create object store if it doesn't exist
      if (!db.objectStoreNames.contains("images")) {
        db.createObjectStore("images", { keyPath: "filename" });
        console.log('Object store "images" created');
      } else {
        console.log('Object store "images" already exists');
      }
    };

    dbOpenRequest.onsuccess = function(event) {
      let db = event.target.result;
      console.log('Database opened successfully');

      let transaction = db.transaction("images", "readonly");
      let store = transaction.objectStore("images");

      let request = store.get(filename);
      request.onsuccess = () => {
        if (request.result) {
          resolve(request.result.data);
        } else {
          reject('Image not found in IndexedDB');
        }
      };
      request.onerror = (event) => reject('Error in request: ' + event.target.error);
    };

    dbOpenRequest.onerror = function(event) {
      reject("IndexedDB could not be opened: " + event.target.errorCode);
    };
  });
};

/*---- Utility functions for IndexedDB Tail ----*/


// Modified fetchImage function
export const fetchImage = (newfilename) => {

  if (newfilename === '預設圖片' || newfilename === '還沒有選擇圖片喔') {
    console.log(`Fetching ${newfilename} is not a valid act, returning...`);
    return Promise.resolve(placeholder);
  } else if(!newfilename){
    console.log(`Fetching empty [newfilename] is not a valid act, returning...`);
    return Promise.resolve(placeholder);
  } else {
    return readFromIDB(newfilename)
      .then(data => {
        //console.log(`Image ${newfilename} found in IndexedDB`);
        return data;
      })
      .catch(() => {
        // Running encodeURIComponent first to prevent error when there's images like '100%.png' with '%'
        const sendNewFilename = encodeURIComponent(newfilename);
        console.log(`Image not found in IndexedDB, fetching [${sendNewFilename}] from network...`);
        /*
        return axiosInstance.get(`/knovia/image/${sendNewFilename}`, { responseType: 'blob' })
          .then(response => {
            const blob = response.data;
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64data = reader.result;
                writeToIDB(newfilename, base64data).then(() => {
                  console.log('Image cached in IndexedDB');
                  resolve(base64data);
                }).catch(error => {
                  console.error('Error caching the image in IndexedDB:', error);
                  resolve(base64data); // Resolve with the image data anyway
                });
              };
              reader.onerror = () => {
                reject(new Error('Error reading the blob'));
              };
              reader.readAsDataURL(blob);
            });
          })
          .catch(error => {
            console.error('Error fetching the image:', error);
            return ''; // Return an empty string or a default image URL on error
          });
        */

        return axiosInstance.get(`/knovia/image/${sendNewFilename}`)
          .then(response => {
            const { contentType, data } = response.data;
            //console.log('HERE ContentType:', contentType);
            //console.log('HERE data:', data);
            const base64data = `data:${contentType};base64,${data}`;
            return writeToIDB(newfilename, base64data).then(() => {
              console.log('Image cached in IndexedDB');
              return base64data;
            }).catch(error => {
              console.error('Error caching the image in IndexedDB:', error);
              return base64data; // Resolve with the image data anyway
            });
          })
          .catch(error => {
            console.error('Error fetching the image:', error);
            return ''; // Return an empty string or a default image URL on error
          });

      });
  }
};

