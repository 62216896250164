import React from 'react';
import { FaStar } from 'react-icons/fa'; // Make sure you have this import for the stars

const RatingComponent = ({ 
  conversationId, 
  setIsRatingModalOpen, 
  selectedRating,

  conversationBoxFontSize,
  conversationBoxBackgroundColor
}) => {
  if (!conversationId) return null; // Don't render if conversationId is not present

  const getStarColor = (ratingValue) => {
    if (ratingValue <= 2) {
      return "#fd7348"; // Red for 1-2 stars
    } else if (ratingValue === 3) {
      return "#fcb752"; // Orange for 3 stars
    } else {
      return "#ffeb3b"; // Yellow for 4-5 stars
    }
  };

  return (
    <div
      style={{
        background: conversationBoxBackgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.5rem 0rem',
      }}
    >
      <div
        style={{ 
          background: '#4ac1c5',
          borderRadius: '5px',
          color: 'white',
          padding: '2px 10px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={() => setIsRatingModalOpen(true)} // Open the modal on click
      >
        <p style={{ margin: '0', fontSize: `${conversationBoxFontSize}px` }}>為此對話評分</p>
        {/* Display the stars */}
        <div style={{ display: 'flex', marginLeft: '4.5px', marginBottom: '2px', cursor: 'pointer' }}>
          {[...Array(5)].map((star, index) => {
            const ratingValue = index + 1;

            return (
              <label key={index} style={{ margin: '0' }}>
                <FaStar
                  className="star"
                  color={ratingValue <= selectedRating ? getStarColor(selectedRating) : "#e4e5e9"}
                  size={14}
                  style={{ cursor: 'pointer' }}
                />
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RatingComponent;