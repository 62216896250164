import React, { useEffect, useRef } from 'react';

const ChatMessages = ({ 
  thisAgent, 
  messages, 
  messagesEndRef, 
  setMessagesEndRef,
  parseTextToJSX, 
  onImageClick,

  conversationBoxHeight,
  conversationBoxFontSize,
  conversationBoxTimeFontSize,
  conversationBoxTimeTextColor,
  conversationBoxBackgroundColor,

  userMessageBackgroundColor,
  AIMessageBackgroundColor,
  userMessageTextColor,
  AIMessageTextColor,
}) => {

  /*
  const localRef = useRef(null);

  useEffect(() => {
    // Link the passed ref to the local ref in the component
    if (messagesEndRef && localRef.current) {
      messagesEndRef.current = localRef.current;
    }
  }, [messagesEndRef]);
  */

  return (

    <div className="chat-messages" ref={setMessagesEndRef} // Use local ref because component is conditionally rendered
      style={{ 
        paddingTop: '2vh',
        overflowX: 'hidden',
        height: `${conversationBoxHeight}px`,
        backgroundColor: conversationBoxBackgroundColor,
      }}
    >
      {[
        {
          sender: 'received',
          text: `您好!我是 { 新創圓夢網客服 }，歡迎與我對話！ 😀
您可以輸入一個問題，以便讓我了解您的提問。 
我目前還在測試學習階段，若無法解決您的疑問，請多多包涵，歡迎幫我打星星，也可以告訴我您的建議，我會努力加油的!`
        },
        ...messages
      ].map((messageItem, index) => {
        if (messageItem.sender === 'loading') {
          return (
            <div key={`${index}${messageItem.id}`} className="dialog-row received-side">
              <div className="dialog-box">
                <div className="loading-dots">
                  <div className="dot" style={{ '--dot-index': 0 }}></div>
                  <div className="dot" style={{ '--dot-index': 1 }}></div>
                  <div className="dot" style={{ '--dot-index': 2 }}></div>
                </div>
              </div>
            </div>
          );
        }
        return (
          <div key={`${index}${messageItem.id}`} className={`dialog-row ${messageItem.sender}-side`}>

            {/* User Message Time */}
            {messageItem.sender === "sent" && 
              <div className="timer-sent">
                <p style={{ margin: '0', color: conversationBoxTimeTextColor, fontSize: `${conversationBoxTimeFontSize}px` }}>
                  {messageItem.time}
                </p>
              </div>
            }

            <div className="dialog-box"
              style={{ 
                backgroundColor: messageItem.sender === "sent" ? userMessageBackgroundColor : AIMessageBackgroundColor 
              }}
            >
              {messageItem.text && 
                <div className="title">
                  <p 
                    style={{ 
                      margin: '0', 
                      color: messageItem.sender === "sent" ? userMessageTextColor : AIMessageTextColor,
                      fontSize: `${conversationBoxFontSize}px` 
                    }}
                  >
                    {parseTextToJSX(messageItem.text)}
                  </p>
                </div>
              }
              {messageItem.imageUrl && (
                <img
                  src={messageItem.imageUrl}
                  alt="Chatbot response"
                  className="chat-image"
                  style={{ marginTop: '2vh', cursor: 'pointer' }}
                  onClick={() => onImageClick(messageItem.imageUrl, messageItem.text)}
                />
              )}
            </div>

            {/* AI Message Time */}
            {messageItem.sender === "received" && 
              <div className="timer-received">
                <p style={{ margin: '0', color: conversationBoxTimeTextColor, fontSize: `${conversationBoxTimeFontSize}px` }}>
                  {messageItem.time}
                </p>
              </div>
            }


          </div>
        );
      })}
    </div>
  );
};

export default ChatMessages;





