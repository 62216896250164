// src/utils/authUtils.js

import axiosInstance from 'utils/axiosInstance';

export const refreshToken = async () => {
  console.log('[authUtils] RUNNING REFRESHTOKEN');
  try {
    const currentAuthUser = JSON.parse(localStorage.getItem("authUser"));
    const refreshTokenValue = currentAuthUser?.refreshToken;
    
    if(refreshTokenValue){
      const response = await axiosInstance.post('/knovia/refresh-token', { refreshToken: refreshTokenValue });
      const { accessToken } = response.data;
      localStorage.setItem("authUser", JSON.stringify({ ...currentAuthUser, accessToken }));
      return accessToken;
    } else {
      return null;
    }
  } catch (error) {
    //console.log('[authUtils.js] authUser removed!');
    //localStorage.removeItem("authUser");
    console.error("Error refreshing token", error);
    return null;
  }
};