import React, { useEffect } from 'react';
import { 
  Col, 
  Row, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import RatingComponent from './modals/RatingComponent';
import ChatMessages from './ChatMessages';

const ChatMessagesModalView = ({ 
  thisAgent, 
  isModalView,
  setIsModalView,
  isWithinCustomerServiceHours,
  callService,
  isRealTime,

  conversationId,
  setIsRatingModalOpen,
  selectedRating,

  messages, 
  messagesEndRef, 
  setMessagesEndRef,
  inputTextareaRef,
  setInputTextareaRef,

  message,
  handleChange,
  handleKeyPress,
  sendMessage,

  parseTextToJSX, 
  onImageClick,

  headerColor,
  headerHeight,
  headerTextColor,
  headerFontSize,
  headerBorderRadius,

  conversationBoxHeight,
  conversationBoxFontSize,
  conversationBoxTimeFontSize,
  conversationBoxTimeTextColor,
  conversationBoxBackgroundColor,

  userMessageBackgroundColor,
  AIMessageBackgroundColor,
  userMessageTextColor,
  AIMessageTextColor,

  inputFontSize,
  inputTextColor,
  inputMarginColor,
  inputBackgroundColor,
  sendIconSize,
  sendIconColor,
}) => {

  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .enlarge-chat-window .modal-content.custom-modal-content {
        border-top-left-radius: ${headerBorderRadius}px !important;
        border-top-right-radius: ${headerBorderRadius}px !important;
        border-bottom-left-radius: ${headerBorderRadius}px;
        border-bottom-right-radius: ${headerBorderRadius}px;
        border: none !important;
      }
      .enlarge-chat-window .modal-content .modal-footer {
        border-bottom-left-radius: ${headerBorderRadius}px;
        border-bottom-right-radius: ${headerBorderRadius}px;
        border: none !important;
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [headerBorderRadius]);

  return (

    <Modal className="enlarge-chat-window" contentClassName="custom-modal-content" 
      isOpen={isModalView} toggle={() => setIsModalView(false)}
      style={{ 
        zIndex: '-1',
        borderTopLeftRadius: `${headerBorderRadius}px`,
        borderTopRightRadius: `${headerBorderRadius}px`,
      }}
    >

      <Row 
        className="chat-header"
        toggle={() => setIsModalView(false)} 
        style={{ 
          //display: 'flex', 
          //flexDirection: 'column',
          //justifyContent: 'center',
          height: `${headerHeight}px`,
          width:'100%', 
          marginLeft:'0px',
          backgroundColor: headerColor,
          borderTopLeftRadius: `${headerBorderRadius}px`,
          borderTopRightRadius: `${headerBorderRadius}px`,
          alignContent: 'center',
        }} 
      >
        <Col xs={9}>
          <p 
            className="chat-header-text"
            style={{
              color: headerTextColor,
              fontSize: `${headerFontSize}px`,
              fontWeight: '500'
            }}
          >
            {`${thisAgent?.agentName}(測試中)` || ''}
          </p>
        </Col>
        <Col xs={3}
          style={{  
            height: '100%',
          }}
        >
          <button className="shrink-chat" onClick={() => setIsModalView(false)} 
            style={{
              position: 'absolute',
              right: '50px',
            }}
          >
            ⤦
          </button>
          <button className="close-chat" onClick={() => setIsModalView(false)}
            style={{     
              position: 'absolute',
              right: '10px'
            }}
          >
            
            &times;
          </button>
        </Col>
      </Row>

      <ModalBody style={{ height: `${Math.max(conversationBoxHeight, 500)}px`, overflowY: 'auto', padding: '0' }}>
        {/* Use the ChatMessages component */}
        <ChatMessages 
          thisAgent={thisAgent}
          messages={messages} 
          messagesEndRef={messagesEndRef} 
          setMessagesEndRef={setMessagesEndRef}
          parseTextToJSX={parseTextToJSX}
          onImageClick={onImageClick}

          conversationBoxHeight={ Math.max(conversationBoxHeight, 500) }
          conversationBoxFontSize={conversationBoxFontSize}
          conversationBoxTimeFontSize={conversationBoxTimeFontSize}
          conversationBoxTimeTextColor={conversationBoxTimeTextColor}
          conversationBoxBackgroundColor={conversationBoxBackgroundColor}

          userMessageBackgroundColor={userMessageBackgroundColor}
          AIMessageBackgroundColor={AIMessageBackgroundColor}
          userMessageTextColor={userMessageTextColor}
          AIMessageTextColor={AIMessageTextColor}
        />
      </ModalBody>

      {conversationId && (
        <RatingComponent
          conversationId={conversationId}
          setIsRatingModalOpen={setIsRatingModalOpen}
          selectedRating={selectedRating}

          conversationBoxFontSize={conversationBoxFontSize}
          conversationBoxBackgroundColor={conversationBoxBackgroundColor}
        />
      )}

      <ModalFooter 
        style={{ 
          display:'flex', 
          flexWrap: 'nowrap', 
          //padding: '2vh 1.5vw 2vh 2vw',
          padding: '8px 5px 8px 25px',
          backgroundColor: inputMarginColor,
          borderTop: 'none',
        }}
      >
        
        {thisAgent?.supportsHumanCustomerService && 
          isWithinCustomerServiceHours(thisAgent?.customerServiceSchedule) && 

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <button 
              className="send-message call-service" 
              onClick={callService} 
              style={{ 
                color: (!conversationId ? 'lightgrey' : sendIconColor),
                padding: '0px 5px',
                backgroundColor: inputMarginColor,
              }}
            >
              {isRealTime ? (
                <i className="bx bxs-bot" 
                  style={{ 
                    fontSize: `${sendIconSize}px`,
                  }}>
                </i>
              ) : (
                <i className="bx bxs-user-detail" 
                  style={{ 
                    fontSize: `${sendIconSize*1.2}px`,
                  }}>
                </i>
              )}
            </button>
          </div>
        }
        <textarea
          rows="1"
          ref={setInputTextareaRef}
          placeholder="想問什麼呢？" 
          value={message}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          style={{ 
            maxHeight: '5rem', 
            width: "100%", 
            border: 'none', 
            resize: 'none',
            outline: '0px',
            WebkitAppearance: 'none',
            boxShadow: 'none',
            overflowY: 'scroll',
            margin: '0 2vw 0 0',
            padding: '0',
            fontSize: '13px',
            padding: `calc(1 / 2*(40px - (${inputFontSize*1.5}px)))`,
            minHeight: '40px',
            fontSize: `${inputFontSize}px`,
            color: inputTextColor,
            backgroundColor: inputBackgroundColor,
          }}
        />
        {/* Send Message Button */}
        <div style={{ display: 'flex', paddingTop: '0px', marginLeft: '6px', width: `${sendIconSize*2}px` }}>
          <button 
            className="send-message" 
            onClick={sendMessage} 
            style={{ 
              padding: '0rem 0.3rem',
              backgroundColor: inputMarginColor,
            }}
          >
            <svg
              width={sendIconSize}
              height={sendIconSize}
              viewBox="0 0 24 24"
              fill={sendIconColor}
              xmlns="http://www.w3.org/2000/svg"
            >{/*➤*/}
              <path d="M2 21l21-9L2 3v7l15 2-15 2v7z" />
            </svg>
          </button>
        </div>

      </ModalFooter>
    </Modal>

  );
};

export default ChatMessagesModalView;





