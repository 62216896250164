import React, { useEffect, useRef, useState } from "react";
import withRouter from "components/Common/withRouter";
import { 
  Col, 
  Row, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { BASE_URL } from "utils/index";
import axiosInstance from 'utils/axiosInstance';
 // WebSocket communication
import { io } from "socket.io-client";

// Custom Utils for Image Uploading
import { dateGen } from 'utils/toolUtils';
import { isValidImage, fetchImage, uploadImg } from 'utils/imageUtils';

import './Chatbot.css';
import ChatMessages from './ChatMessages';

/*---- Example Message ----*/
/*
const messages = [
  { id: 1, text: "Hello, how can I help you?", sender: "received", time: "18:45"  },
  { id: 2, text: "I have a question about my order.", sender: "sent", time: "18:45"  },
  { id: 3, text: "Sure, what is your order number?", sender: "received", time: "18:46"  },
  { id: 4, text: "It's #1234.", sender: "sent", time: "18:46"  }
];
*/

// Function to parse text and convert it to JSX with bold formatting
const parseTextToJSX = (text) => {
  // Use regex to remove 'undefined' from the end of the string if it exists
  const cleanedText = text.replace(/undefined$/, '');

  // Replace LaTeX-style \text{} with the actual content
  let processedText = cleanedText.replace(/\\text\{([^}]+)\}/g, '$1');

  // Replace LaTeX-style \[ ... \] (math expressions) with the content in parentheses
  processedText = processedText.replace(/\\\[(.*?)\\\]/gs, '($1)');

  // Replace \times with the multiplication symbol ×
  processedText = processedText.replace(/\\times/g, '×');

  // Replace \% with the percentage symbol %
  processedText = processedText.replace(/\\%/g, '%');

  // Define the URL regex pattern (http or https)
  const urlPattern = /https?:\/\/[^\s)）\]，。]+/g;

  // Split the text into parts by bold text and URLs
  const parts = processedText.split(/(\*\*[^*]+\*\*|https?:\/\/[^\s)）\]，。]+)/);

  return parts.map((part, index) => {
    // Handle bold text
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    // Handle URLs
    if (urlPattern.test(part)) {
      return (
        <a href={part} key={index} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    // For regular text (including transformed math expressions)
    return <span key={index}>{part}</span>;
  });
};

const Chatbot = props => {


  /*---- Props and States ----*/

  const { thisCb, chatbotImg, token } = props;
  // Store the token in a global variable
  // When chatbot used in client sites
  if(token){
    window.chatbotAuthToken = token;
  // When chatbot used in own platform
  } else {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const authToken = authUser?.token;
    window.chatbotAuthToken = authToken;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const [isRealTime, setIsRealTime] = useState(false); // Track if real-time customer service is active
  const [socket, setSocket] = useState(null); // Store WebSocket connection

  useEffect(() => {
    // Cleanup function to disconnect the socket when component unmounts
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]); // Runs when the `socket` changes or the component unmounts

  const [message, setMessage] = useState(''); // message within dialog input
  const [messages, setMessages] = useState([]); // the whole set of dialog

  const messagesEndRef = useRef(null); // For conversation scroll to bottom
  const inputTextareaRef = useRef(null); // For textarea height adjustment

  const modalMessagesEndRef = useRef(null); // For conversation scroll to bottom
  const modalInputTextareaRef = useRef(null); // For textarea height adjustment

  let jsonPart='', textPart='';

  /*---- Props and States Tail ----*/



  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Keep small chat window closed and open large window
  useEffect(()=>{
    if(isOpen && isSmallScreen){
      setIsOpen(false);
      setIsModalView(true);
    }
  },[isOpen, isSmallScreen])


  /*---- Text Window Related Settings ----*/

  // Use useEffect to scroll to the bottom every time messages change
  useEffect(() => {
    console.log('scrolling to the newest message', messagesEndRef.current);
    if (messagesEndRef.current) { 
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
    if (modalMessagesEndRef.current) { 
      modalMessagesEndRef.current.scrollTop = modalMessagesEndRef.current.scrollHeight;
    }
  }, [messages]); // Only runs when messages change

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    if (inputTextareaRef.current) {
      inputTextareaRef.current.style.height = "auto"; 
      if(inputTextareaRef.current.scrollHeight>36){
        inputTextareaRef.current.style.height = `${inputTextareaRef.current.scrollHeight}px`;
      } else {
        inputTextareaRef.current.style.height = '18px';
      }
    }
  };

  // Change in textarea input
  const handleChange = (e) => {
    setMessage(e.target.value);
    adjustTextareaHeight();
  };

  const toggleChatWindow = () => { setIsOpen(!isOpen) };
  const closeChatWindow = () => { setIsOpen(false) };

  /*---- Text Window Related Settings ----*/


  const callService = async ()=>{

    if(!conversationId){return;}

    try {
      const response = await axiosInstance.post('/knovia/call-service', { 
        conversationId: conversationId 
      });
      console.log('response:', response);
    } catch (error) {
      console.error('Error requesting real customer service:', error);
    }

    if (!isRealTime) {
      // If real-time service is not active, switch to WebSocket
      const newSocket = io(BASE_URL); // Adjust to your backend WebSocket URL
      
      // Join a room using the conversationId as roomId
      newSocket.emit('joinRoom', { username: "User", room: conversationId });

      // Listen for incoming messages in the room
      newSocket.on("chatMessage", (msg) => {
        // Receive message from WebSocket and update UI
        console.log('[Chatbot.js] received msg:', msg);
        console.log('[Chatbot.js] received Message:', msg.message);
        if(msg.sender!=='User'){
          setMessages((prevMessages) => [...prevMessages, 
            {
              conversationId: msg.conversationId,
              sender: msg.sender,
              text: msg.message,
              time: new Date(msg.time).toLocaleTimeString(),
            }
          ]);
        }
      });

      // Set the WebSocket and update the UI
      setSocket(newSocket);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "AI", text: "[已為您轉接真人客服...請稍待片刻]" },
      ]);
    } else {
      // If real-time service is active, disconnect WebSocket and switch back to AI
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "AI", text: "[已為您轉接至AI客服...]" },
      ]);
    }
    setIsRealTime(!isRealTime); // Toggle real-time service state
  };


  /*---- Conversation with Backend ----*/
  useEffect(()=>{ console.log('Now messages:', messages) },[messages])

  const sendMessage = async () => {
    if (!message.trim()) return;

    // Reset the input field after sending the message
    setMessage('');
    if (inputTextareaRef && inputTextareaRef.current && inputTextareaRef.current.style) {
      if(isModalView){
        // Input in large window
        inputTextareaRef.current.style.height = '18px';
      } else {
        // Input in small window
        inputTextareaRef.current.style.height = '40px';
      }
    }

    if (message.trim()) {

      /*---- Optimistic Update of user message ----*/

      const tempMessageId = Date.now(); // Temporary ID for optimistic UI update
      const tempBotMessageId = 'tempBot';
      let conversation, processedMessages, botImageMessages;

      let parsedData = {};
      let jsonPart, textPart;
      let optimisticUserMessage={}, optimisticBotMessage={};

      if (isRealTime) {
        optimisticUserMessage = {
          id: tempMessageId,
          text: message,
          sender: 'User',
          time: new Date().toLocaleTimeString(),
        };
        // Add the optimistic user message
        setMessages(prevMessages => [...prevMessages, optimisticUserMessage]);
      } else {
        optimisticUserMessage = {
          id: tempMessageId,
          text: message,
          sender: 'User',
          time: new Date().toLocaleTimeString(),
        };

        optimisticBotMessage = {
          id: tempBotMessageId,
          text: message,
          sender: 'loading',
          time: new Date().toLocaleTimeString(),
        };

        // Add the optimistic user message and bot response
        setMessages(prevMessages => [...prevMessages, optimisticUserMessage, optimisticBotMessage]);
      }
      /*---- Optimistic Update of user message ----*/



      /*---- Post and Receive streaming Response ----*/

      if (isRealTime) {
        // If WebSocket is connected, send message through WebSocket
        socket.emit('chatMessage', {
          sender: 'User',
          message,
          room: conversationId,
          time: new Date(),
        });
      } else {
        const data = { 
          sender: 'User',
          message, 
          chatbotId: thisCb._id,
          conversationId,
        };

        try {

          const response = await axiosInstance.post(`/knovia/conversations`, data,
            {
              headers: { 'Content-Type': 'application/json' },
              onDownloadProgress: (evt) => {

                const currentResponse = evt.event.target.response.replace('undefined', '');


                // Bot message from backend completed
                let splitIndex = currentResponse.indexOf('\n\n\n\n');
                
                if (splitIndex !== -1) {
                  textPart = currentResponse.substring(0, splitIndex);
                  jsonPart = currentResponse.substring(splitIndex + 4);

                  console.log('Text Part:', textPart);
                  console.log('Json Part:', jsonPart);

                  // Get JSON Part
                  try {
                    if (jsonPart.trim()) {
                      const parsedData = JSON.parse(jsonPart);

                      // Assuming parsedData contains the necessary information
                      ({ conversation, processedMessages, botImageMessages } = parsedData);

                      // Check and update conversation ID if needed
                      if (!conversationId && conversation._id) { setConversationId(conversation._id) }

                      if(processedMessages){
                        // Update the messages in the UI
                        setMessages(prevMessages => {

                          // Filter out the loading and optimistic messages
                          let updatedMessages = prevMessages.filter(message => message.id !== tempMessageId && message.id !== tempBotMessageId);

                          // Add the user message confirmed by the server
                          if (processedMessages.userMessage) {
                            updatedMessages.push({
                              ...optimisticUserMessage,
                              id: processedMessages.userMessage._id, // Update ID with the one from server
                            });
                          }

                          // Add the new bot message
                          if (processedMessages.botMessage) {
                            updatedMessages.push({
                              id: processedMessages.botMessage._id,
                              text: textPart,
                              sender: 'AI',
                              time: new Date().toLocaleTimeString(), // Adjust the time as per your need
                            });
                          }

                          // Add the bot image messages
                          if (botImageMessages && botImageMessages.length > 0) {
                            
                            botImageMessages.forEach((botImageMessage, index) => {
                              const base64Image = botImageMessage.image.data;
                              //console.log('HERE21 base64Image:', base64Image.slice(0,100), '...');
                              const imageUrl = `data:${botImageMessage.image.contentType};base64,${base64Image}`;

                              updatedMessages.push({
                                id: `${processedMessages.botMessage._id}-image-${index}`,
                                text: botImageMessage.text,
                                imageUrl: imageUrl,
                                sender: 'AI',
                                time: new Date().toLocaleTimeString(),
                              });
                            });
                          }

                          return updatedMessages;
                        });
                      }

                    }
                    
                  } catch (e) {
                    console.log('error', e);
                  }

                // Bot message from backend not completed yet
                } else {
                  // Update the messages in the UI
                  setMessages(prevMessages => {
                    // Filter out the previous incomlete bot message
                    let updatedMessages = prevMessages.filter(message => message.id !== tempBotMessageId);
                    let newStreamMessage = {};

                    // Add the new bot message
                    if (currentResponse) {
                      newStreamMessage = {
                        id: tempBotMessageId,
                        text: currentResponse,
                        sender: 'AI',
                        time: new Date().toLocaleTimeString(), // Adjust the time as per your need
                      }
                    }

                    return [...updatedMessages, newStreamMessage];
                  });
                }


              }
            }
          );

        } catch (error) {
          console.error('Error fetching stream:', error);
        }
      }

      /*---- Update User Message Tail ----*/

    }

  };
  /*---- Conversation with Backend ----*/




  /*---- Modal viewing the images ----*/

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTitle, setSelectedImageTitle] = useState('');

  const toggleModal = () => setModalOpen(!modalOpen);

  const onImageClick = (imageData, imageTitle='瀏覽圖片') => {
    setSelectedImageTitle(imageTitle);
    setSelectedImage(imageData);
    setModalOpen(true);
  };

  /*---- Modal viewing the images Tail ----*/


  const [isModalView, setIsModalView] = useState(false);

  // Function to check if the current time falls within the customer service schedule
  const isWithinCustomerServiceHours = (customerServiceSchedule) => {
    if(customerServiceSchedule){
      const currentDate = new Date();
      const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
      const currentTime = currentDate.toTimeString().slice(0, 5); // Get current time in 'HH:MM' format

      const scheduleForToday = customerServiceSchedule[currentDay];
      if (!scheduleForToday || !scheduleForToday.start || !scheduleForToday.end) {
        // No schedule for today or invalid times
        return false;
      }

      // Compare current time with the start and end time for today
      const isAfterStart = currentTime >= scheduleForToday.start;
      const isBeforeEnd = currentTime <= scheduleForToday.end;
      return isAfterStart && isBeforeEnd;
    }
    return true;
  };




  /*---- Drag and drop Images ----*/
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('drag enter');
    setIsDragging(true);  // Add visual feedback by setting dragging state
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('drag leave');
    setIsDragging(false);  // Remove visual feedback when dragging leaves
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    setIsDragging(false);  // Remove visual feedback when drop occurs

    if (files.length > 0) {
      const imageFiles = Array.from(files).filter(file => file.type.startsWith('image/'));
      
      if (imageFiles.length > 0) {
        console.log("Images dropped: ", imageFiles);
        // Handle image upload
        uploadImages(imageFiles);
      }
    }
  };

  /*---- Upload New Image ----*/

  const [imgFile, setImgFile] = useState(null);
  const [imgError, setImgError] = useState('');

  const [imgFilename, setImgFilename] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imgUploadLoading, setImgUploadLoading] = useState(false);
  const [imgUploadStatus, setImgUploadStatus] = useState('');

  const uploadImages = (imageFiles) => {
    setImgUploadStatus('');
    const imgFile = imageFiles[0];
    if (imgFile) {
      console.log("Selected file:", imgFile);
      if (isValidImage(imgFile)) {
        setImgFile(imgFile);
        setImgError('');
      } else {
        setImgError('檔案格式不正確，請上傳 jpg 或 png 檔');
      }
    }
  };

  // Change in uploaded file
  useEffect(() => {

    async function setImageFile(){
      if (imgFile) {
        setImgUploadStatus('');
        const date14string = await dateGen();
        const tempUserMessageId = 'tempUser';
        const newImgFilename = date14string+'_'+imgFile.name;

        try {
          setImgUploadLoading(true);
          const {newImageUrl, uploadStatus} = await uploadImg(newImgFilename, imgFile);
          setImgUploadLoading(false);
          setImgFilename(newImgFilename);
          setImgUploadStatus(uploadStatus);
          const previewImage = await fetchImage(newImgFilename);
          setImageUrl(previewImage);

          const lastUnderscoreIndex = newImgFilename.lastIndexOf('_');
          const showNewImgFilename = newImgFilename.substring(lastUnderscoreIndex + 1);

          setMessages((prevMessages) => [...prevMessages, 
            {
              conversationId: tempUserMessageId,
              sender: 'User',
              text: showNewImgFilename,
              imageUrl: newImageUrl,
              time: new Date().toLocaleTimeString(),
            }
          ]);

        } catch (error) {
          console.log('error:', error);
          setImgUploadLoading(false);
        }

      }
    }

    setImageFile();

  }, [imgFile]);

  /*---- Upload Image Tail ----*/
  /*---- Drag and drop Images ----*/




  
  /*---- Main ChatBot Component ----*/

  return (
    <React.Fragment>
      <div className="chatbot-container">


        {/* Chatbot Window */}
        <div 
          className={`chat-window 
            ${(isOpen&&!isModalView&&!isSmallScreen) ? 'open' : ''}
            ${isDragging ? 'dragging' : ''}`
          } 
          style={{ right: '15vw', zIndex: '-1' }}
        >

          {/* Chat Window Header Bar */}
          <div className="chat-header">
            <Row style={{ width:'100%', marginLeft:'0px' }}>
              <Col xs={9} 
                style={{ 
                  display: 'flex',
                  alignContent: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <h5 className="chat-header-text">{ thisCb.cbName }</h5>
              </Col>
              <Col xs={3}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
              >
                <button className="enlarge-chat" onClick={() => setIsModalView(true)} style={{ display: isModalView ? 'none' : 'block' }}>⤡</button>
                <button className="close-chat" onClick={closeChatWindow}>&times;</button>
              </Col>
            </Row>
          </div>

          {/* Chat Window Messages */}
          <ChatMessages 
            thisCb={thisCb}
            messages={messages} 
            messagesEndRef={messagesEndRef} 
            parseTextToJSX={parseTextToJSX}
            onImageClick={onImageClick}
            isDragging={isDragging}
            handleDragEnter={handleDragEnter}
            handleDragLeave={handleDragLeave}
            handleDrop={handleDrop}
          />


          {/* Chat Input */}
          <div className="chat-input-104">

            {thisCb.supportsHumanCustomerService &&
              isWithinCustomerServiceHours(thisCb?.customerServiceSchedule) && 
              <button 
                className="send-message call-service" 
                onClick={callService} 
                style={{ 
                  color: (!conversationId ? 'lightgrey' : '#2196F3') 
                }}
              >
                {isRealTime ? (
                  <i className="bx bxs-bot" style={{ fontSize: '1.5rem' }}></i>
                ) : (
                  <i className="bx bxs-user-detail" style={{ fontSize: '1.5rem' }}></i>
                )}
              </button>
            }

            <textarea
              rows="1"
              ref={inputTextareaRef}
              placeholder="想問什麼呢？" 
              value={message}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              style={{ 
                maxHeight:'5rem', 
                width: "100%", 
                border:'none', 
                resize: 'none',
                outline: '0px',
                WebkitAppearance: 'none',
                boxShadow: 'none',
                height: '40px',
                fontSize: '13px'
              }}
            />
            {/* Send Message Button */}
            <button 
              className="send-message" 
              onClick={sendMessage} 
              style={{ padding: '0vh 0.5vw' }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >{/*➤*/}
                <path d="M2 21l21-9L2 3v7l15 2-15 2v7z" />
              </svg>
            </button>
          </div>

        </div>

      </div>


      {/* Chatbot Icon */}
      <button className="chatbot-button" onClick={toggleChatWindow} style={{ right: '1vw', zIndex: '1' }}>
        <img 
          className="chatbot-img" 
          style={{ 
            width: '8rem',
            height: '8rem',
            borderRadius: '30%',
            objectFit: 'cover',
            //maxWidth: '10vw'
          }} 
          src={ chatbotImg } 
          alt="Chat"
        />
      </button>


      {/* Big Modal Viewing Bot Messages */}
      <Modal className="enlarge-chat-window" isOpen={isModalView} toggle={() => setIsModalView(false)}>
        <Row style={{ width:'100%', marginLeft:'0px' }} toggle={() => setIsModalView(false)} className="chat-header">
          <Col xs={9}>
            <h5 className="chat-header-text">{ thisCb.cbName }</h5>
          </Col>
          <Col xs={3}
            style={{  
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              paddingRight: '1vh'
            }}
          >
            <button className="shrink-chat" onClick={() => setIsModalView(false)} style={{ paddingBottom: '1vh' }}>⤦</button>
            <button className="close-chat" onClick={() => setIsModalView(false)}>&times;</button>
          </Col>
        </Row>
        <ModalBody style={{ height: '70vh', overflowY: 'auto', padding: '0' }}>
          {/* Use the ChatMessages component */}
          <ChatMessages 
            thisCb={thisCb}
            messages={messages} 
            messagesEndRef={modalMessagesEndRef} 
            parseTextToJSX={parseTextToJSX}
            onImageClick={onImageClick}
          />
        </ModalBody>
        <ModalFooter style={{ display:'flex', flexWrap: 'nowrap', padding: '2vh 1rem 2vh 1.5rem' }}>
          {thisCb.supportsHumanCustomerService &&
            isWithinCustomerServiceHours(thisCb?.customerServiceSchedule) && 
            <button 
              className="send-message call-service" 
              onClick={callService} 
              style={{ 
                color: (!conversationId ? 'lightgrey' : '#2196F3') 
              }}
            >
              {isRealTime ? (
                <i className="bx bxs-bot" style={{ fontSize: '1.5rem' }}></i>
              ) : (
                <i className="bx bxs-user-detail" style={{ fontSize: '1.5rem' }}></i>
              )}
            </button>
          }
          <textarea
            rows="1"
            ref={inputTextareaRef}
            placeholder="想問什麼呢？" 
            value={message}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            style={{ 
              maxHeight: '5rem', 
              width: "100%", 
              border: 'none', 
              resize: 'none',
              outline: '0px',
              WebkitAppearance: 'none',
              boxShadow: 'none',
              overflowY: 'hidden',
              margin: '0 2vw 0 0',
              padding: '0',
              fontSize: '13px',
            }}
          />
          <button 
            className="send-message" 
            onClick={sendMessage} 
            style={{ padding: '0vh 0vw' }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >{/*➤*/}
              <path d="M2 21l21-9L2 3v7l15 2-15 2v7z" />
            </svg>
          </button>
        </ModalFooter>
      </Modal>




      {/* Modal Viewing Bot Images */}
      <Modal isOpen={modalOpen} toggle={toggleModal} style={{ display: 'flex', width: 'fit-content', maxWidth: 'fit-content' }}>
        <ModalHeader toggle={toggleModal}>{selectedImageTitle || '瀏覽圖片'}</ModalHeader>
        <ModalBody style={{ width: 'fit-content', height: 'auto', maxWidth:'75vw' }}>
          {selectedImage && (
            <img src={selectedImage} alt="Selected" style={{ maxWidth: '70vw', maxHeight: '70vh' }} />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>關閉</Button>
        </ModalFooter>
      </Modal>


    </React.Fragment>
  );
};

export default withRouter(Chatbot);






